import flatMap from 'lodash/flatMap'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import type { Country, InputItem, Language, Lead, LibraryItem, Tag, TagItem, TagsParams, User } from '@/types'
import { PARAMETERS_NAMES_MAP, PIPELINES, ROLES, TAGS } from '@/constants'

export const useGetUsersItems = async (
  groupByRole?: boolean,
  force?: boolean,
  onlyActive = false
): Promise<InputItem[]> => {
  const users = await useGetUsers(force)
  if (!groupByRole) {
    return users.map((a: User) => ({ value: a.id, text: a.name, name: a.name, image: a.image })) as InputItem[]
  } else {
    const items = users
      .map((u) => ({
        value: u.id,
        text: u.name,
        role: u.role?.name,
        roleCode: u.role?.code,
        status: u.status,
        name: u.name,
        image: u.image,
      }))
      .filter((u) => !onlyActive || u.status !== 4)
    const sortedData = sortBy(items, 'role')
    const groupedData = groupBy(sortedData, 'role')

    const transformedArray = flatMap(groupedData, (items, key) => {
      return [{ value: 'heading', text: key }, ...items]
    })

    return transformedArray
  }
}

export const useGetAllUsersItems = async (groupByRole?: boolean, force?: boolean): Promise<InputItem[]> => {
  const users = await useGetAllUsers(force)
  if (!groupByRole) {
    return users.map((a: User) => ({ value: a.id, text: a.name, name: a.name, image: a.image })) as InputItem[]
  } else {
    const items = users.map((u) => ({
      value: u.id,
      text: u.name,
      role: u.role?.name,
      roleCode: u.role?.code,
      name: u.name,
      image: u.image,
    }))
    const sortedData = sortBy(items, 'role')
    const groupedData = groupBy(sortedData, 'role')

    const transformedArray = flatMap(groupedData, (items, key) => {
      return [{ value: 'heading', text: key }, ...items]
    })

    return transformedArray
  }
}

export const useGetAgentsItems = async (
  { withDeleted }: { withDeleted?: boolean } = { withDeleted: false }
): Promise<InputItem[]> => {
  const users = await useGetUsers(false, withDeleted)
  return users
    .filter((a: User) => a.role?.code === ROLES.AGENT)
    .map((a: User) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetAgentsAndManagersItems = async (): Promise<InputItem[]> => {
  const users = await useGetUsers()
  return users
    .filter((a: User) => [ROLES.AGENT, ROLES.MANAGER].includes(a.role?.code as string))
    .map((a: User) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetManagersItems = async (
  { withDeleted }: { withDeleted?: boolean } = { withDeleted: false }
): Promise<InputItem[]> => {
  const users = await useGetUsers(false, withDeleted)
  return users
    .filter((a: User) => [ROLES.MANAGER].includes(a.role?.code as string))
    .map((a: User) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetFinanceManagersItems = async (
  { withDeleted }: { withDeleted?: boolean } = { withDeleted: false }
): Promise<InputItem[]> => {
  const users = await useGetUsers(false, withDeleted)
  return users
    .filter((a: User) => [ROLES.FINANCE_MANAGER].includes(a.role?.code as string))
    .map((a: User) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetSourcesItems = async (): Promise<InputItem[]> => {
  const sourcesResponse = await useGetSources()
  return sourcesResponse.data.map((a: any) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetCountriesItems = async (): Promise<InputItem[]> => {
  const countriesResponse = await useCountries()
  return countriesResponse.map((a: Country) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetCampaignsItems = async (): Promise<InputItem[]> => {
  const { data } = await useGetCampaigns()
  return data.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetLanguageItems = async (): Promise<InputItem[]> => {
  const languagesResponse = await useLanguages()
  return languagesResponse.map((a: Language) => ({ value: a.id, text: a.name, code: a.code })) as InputItem[]
}

export const useGetLeadSourcesItems = async (name?: string): Promise<InputItem[]> => {
  const sourcesResponse = await useGetLeadSources(name)
  return sourcesResponse.data.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetPropertyTypesItems = async (): Promise<InputItem[]> => {
  const propertyTypes = await usePropertyTypes()
  return propertyTypes.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetLocationsItems = async (): Promise<InputItem[]> => {
  const locations = await useLocations()
  return locations.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetInterestsItems = async (): Promise<InputItem[]> => {
  const interests = await useInterests()
  return interests.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetBedroomItems = async (): Promise<InputItem[]> => {
  const bedrooms = await useBedrooms()
  return bedrooms.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetLanguagesItems = async (): Promise<InputItem[]> => {
  const languages = await useLanguages()
  return languages.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetStagesItems = async (): Promise<InputItem[]> => {
  const pipeline = await useGetPipelineByCode(PIPELINES.SALES)
  return pipeline.stages.map((s: LibraryItem) => ({ value: s.id, text: s.name }))
}

export const useGetResolutionsItems = async (): Promise<InputItem[]> => {
  const resolutions = await useResolutions()
  return resolutions.map((a: LibraryItem) => ({ value: a.id, text: a.name })) as InputItem[]
}

export const useGetRotationsParameterItems = async (): Promise<InputItem[]> => {
  const response = await useGetRotationsParameters()
  return Object.entries(response.data)
    .filter(([_, v]) => v?.length)
    .map((i) => ({
      value: i[0],
      text: PARAMETERS_NAMES_MAP.get(i[0]) as string,
    }))
}

export const useGetRotationGroupsItems = async () => {
  const groups = await useGetRotationGroups()
  return groups.data.map((i: LibraryItem) => ({ value: i.id, text: i.name }))
}

const getFormattedTags = (tags: Tag[]) => {
  return tags.map((i: LibraryItem) => {
    const tag = {
      value: i.id,
      text: i.name,
      icon: useGetTagPropertiesByCode(i.code)?.icon,
      code: i.code,
    }

    if (i.code === TAGS.SHADOW_ORIGINAL_LEAD) {
      return {
        ...tag,
        text: 'Shadow-original',
      }
    }

    if (i.code === TAGS.SHADOW_COPY_LEAD) {
      return {
        ...tag,
        text: 'Shadow-copy',
      }
    }

    return {
      value: i.id,
      text: i.name,
      icon: useGetTagPropertiesByCode(i.code)?.icon,
      code: i.code,
    }
  })
}

export const useGetTagsItems = async (): Promise<TagItem[]> => {
  const tags = await useGetTags()

  return getFormattedTags(
    tags.filter(({ code }) =>
      [
        TAGS.HIGH_QUALITY_LEAD,
        TAGS.IN_UAE,
        TAGS.HOT_LEAD,
        TAGS.SHADOW_ORIGINAL_LEAD,
        TAGS.SHADOW_COPY_LEAD,
        TAGS.REFERRAL,
        TAGS.ENRICHED,
        TAGS.FBYB,
      ].includes(code)
    )
  )
}

export const useGetDealTagsItems = async (): Promise<TagItem[]> => {
  const tags = await useGetDealTags()

  return getFormattedTags(tags)
}

export const useGetActiveLeadsItems = async () => {
  const response = await useGetActiveLeads()
  return response.data.map((i: Lead) => ({ value: i.id, text: i.name, email: i.email }))
}

export const useGetFinanceDealStagesItems = async () => {
  const items = await useFinanceDealStages()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceDealStatusesItems = async () => {
  const items = await useFinanceDealStatuses()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceDealTypesItems = async () => {
  const items = await useFinanceDealTypes()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceDealReasonsItems = async () => {
  const items = await useFinanceDealReasons()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceDealSourcesItems = async () => {
  const items = await useFinanceDealSources()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceDealActionStatusesItems = async () => {
  const items = await useFinanceDealActionStatuses()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceDealActionTypesItems = async () => {
  const items = await useFinanceDealActionTypes()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceProjectsItems = async () => {
  const items = await useFinanceProjects()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceDevelopersItems = async () => {
  const items = await useFinanceDevelopers()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetFinanceBuyersItems = async () => {
  const items = await useFinanceBuyers()
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetSpamStatusesItems = async () => {
  const items = await useSpamStatuses()
  return items.map((item) => ({
    ...item,
    text: item.name === 'true' ? 'Marked as spam' : 'Not spam',
    value: item.name,
  }))
}

export const useGetSpamServicesItems = async () => {
  const items = await useSpamServices()
  return items.map((i) => ({ value: i.name, text: i.name }))
}

export const useSerializeLibraryItems = (items: LibraryItem[]): InputItem[] => {
  return items.map((i) => ({ value: i.id, text: i.name }))
}

export const useGetPartnersIds = async () => {
  const items = await useGetPartners()
  return items.map((i) => ({ value: i.id, text: i.id.toString() }))
}

export const useGetPartnersItems = async () => {
  const items = await useGetPartners()
  return items.map((i) => ({ value: i.id, text: i.name }))
}
